const menuBurger = document.querySelector('#menu-menu-principal');
const sideBarBurger = document.querySelector('#sidebar > ul')?.cloneNode(true);
const burgerToggle = document.querySelector('#burger-toggle');
const headerInfosCta = document.querySelectorAll('.header-infos__cta');
const menuItemsHasChildren = document.querySelectorAll(
  '.menu-item-has-children',
  '.menu-item-has-children > a'
);

const openMenu = () => {
  burgerToggle.setAttribute('aria-expanded', 'true');
  menuBurger.setAttribute('aria-hidden', 'false');
};

const closeMenu = () => {
  burgerToggle.setAttribute('aria-expanded', 'false');
  menuBurger.setAttribute('aria-hidden', 'true');
};

const closeSubMenu = (item) => {
  item.setAttribute('aria-expanded', 'true');

  menuItemsHasChildren.forEach((itembis) => {
    itembis !== item && itembis.setAttribute('aria-expanded', 'false');
  });
};

const buildBurger = () => {
  burgerToggle.addEventListener('click', () => {
    const isOpen = burgerToggle.getAttribute('aria-expanded') === 'true';
    isOpen ? closeMenu() : openMenu();
  });

  if (menuBurger.querySelector('.menu-item-burger')) return;

  headerInfosCta.forEach((cta) => {
    cta = cta.cloneNode(true);
    cta.classList.remove('header-infos__cta');
    cta.classList.add('menu-item-burger');
    menuBurger.appendChild(cta);
  });

  menuBurger.appendChild(sideBarBurger);

  menuItemsHasChildren.forEach((item) => {
    if (item.tagName === 'A') item = item.parentNode;

    item.addEventListener('click', (e) => {
      if (
        e.target.tagName === 'A' &&
        e.target.parentNode.parentNode.classList.contains('sub-menu')
      )
        return;
      e.preventDefault();
      const isOpen = item.getAttribute('aria-expanded') === 'true';
      isOpen ? item.setAttribute('aria-expanded', 'false') : closeSubMenu(item);
    });
  });
};

const destroyBurger = () => {
  if (menuBurger.querySelector('.menu-item-burger')) {
    const menuItemsBurger = document.querySelectorAll('.menu-item-burger');

    menuItemsBurger.forEach((item) => {
      menuBurger.removeChild(item);
    });
  }

  if (menuBurger.querySelector('#menu-menu-sidebar')) {
    menuBurger.removeChild(sideBarBurger);
  }
};

window.matchMedia('(max-width: 992px)').matches && buildBurger();

window.addEventListener('resize', () => {
  window.matchMedia('(max-width: 992px)').matches
    ? buildBurger()
    : destroyBurger();
});
